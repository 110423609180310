.ant-form-item-label {
  line-height: 30px;
}


/**
 * Hack para remover a bosta do title que o Menu.ItemGroup
 * adiciona ao Header mesmo quando title é null
 */
 .auth-menu-group > .ant-menu-item-group-title {
  display: none;
}

.ant-divider-inner-text {
  color: #d9d9d9;
  font-size: 0.8rem;
}


.clickable-table-row {
  cursor: pointer
}

.table-row-selected {
  background: #d8e6df;
}

.table-row-close-to-expire {
  background: #fff4f4;
}

.item-error {
  color: red !important;
}

.ant-table-row-warning {
  background-color: #fff8f0;
}

.ant-table-row-current {
  background-color: #f7fffb;
}
.ant-timeline-item-head-green {
  color: #1da57a !important;
  border-color: #1da57a !important;
}

.ant-collapse-header {
  cursor: pointer !important;
}
